#tooltipOverlay {
  pointer-events: none;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#tooltipOverlay > * {
  display: block;
  position: absolute;

  color: white;
  border: 1px solid gray;
  border-radius: 3px;
  padding: 3px;

  background: rgba(0, 0, 0, 0.8);
}

@supports (backdrop-filter: blur(15px)) {
  #tooltipOverlay > * {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
  }
}

.has-tooltip {
  display: contents;
}

#tooltipOverlay ul {
  margin: 0.2em 0em;
  padding: 0 1.5em;
}
