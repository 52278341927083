.history-controls {
  text-align: center;
  width: 100%;
}
.history-controls button {
  transition: transform 0.1s;
}
.history-controls button.hidden {
  pointer-events: none;
  transform: translateY(calc(-100% - 2px));
}
