#overlay {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

#overlay:empty {
  display: none;
}

.overlayBackground {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}
