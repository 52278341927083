.lobby-wrapper {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  box-sizing: border-box;
  background: hsl(220deg, 30%, 5%);
  padding: 50px 0;
  display: grid;
  justify-content: center;
  align-content: start;
}

@media screen and (max-width: 900px) {
  .lobby-wrapper {
    justify-content: start;
  }
}

.lobby {
  box-shadow: 0 0 50px black;
  padding: 1em;
  box-sizing: border-box;
  width: 900px;
  display: grid;
  grid:
    "title title" auto
    "players map" auto
    "empty invite" auto
    "rest rest" auto
    / 128px 1fr;
  padding: 1em;
  padding-top: 0em;
  gap: 1em;

  border: 17px solid transparent;
  border-image: url(../ui-utils/frame.png);
  border-image-slice: 17;
  border-image-repeat: stretch;

  background: #111218;
}

.lobby h1 {
  grid-area: title;
}

.lobby-map-container {
  grid-area: map;
  text-align: center;

  display: grid;
  grid: "map" auto / auto;
}

.lobby-map-container > * {
  grid-area: map;
}

.lobby-map-container > div {
  margin-top: 1em;
}

.lobby-map {
  border: 1px solid black;
  max-width: 100%;
}

.lobby-player-list {
  grid-area: players;
}

.lobby-player-list .player-box-name {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: column;
  place-items: stretch;
}

.lobby-player-list .player-box-name .dropdown-button {
  /* This is a very specific combination of attributes that seems to just work out. */
  height: 20px;
  display: grid;
  place-content: center;
  padding-top: 3px;
  box-sizing: border-box;
}

.lobby-player-list-header {
  font-size: 0.7em;
  text-align: center;
}
.lobby-player-portrait {
  position: relative;
  width: 64px;
}
.lobby-player-portrait .dropdown-button {
  position: absolute;
  bottom: 5px;
  right: 2px;
  color: black;
  background-color: hsla(0, 0%, 100%, 30%);
}
.lobby-player-portrait-dropdown {
  display: grid;
  grid-template-columns: repeat(3, 64px);
}
.lobby-player-portrait-dropdown img {
  width: 64px;
}
.lobby-player-list .dropdown-button {
  place-self: center;
}
.lobby-player-name {
  transition: background-color 0.3s, color 0.3s;
}
.lobby-player-name::after {
  font-size: x-small;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.5em;
  filter: contrast(50%);
}
.lobby-player-name.lobby-player-host::after {
  content: "(host)";
}
.lobby-player-name.lobby-player-self::after {
  content: "(me)";
}
.lobby-player-name.lobby-player-self.lobby-player-host::after {
  content: "(me, host)";
}
.lobby-player-ready {
  place-self: center;
  font-size: 32px;
}

.lobby-rest {
  grid-area: rest;
  display: grid;
  grid: "settings main-button" auto / 1fr 0.5fr;
  align-items: center;
}

.lobby-main-button-area {
  grid-area: main-button;
  display: grid;
  align-self: center;
  justify-self: stretch;
}

.lobby-main-button {
  display: block;
  font-size: 1.5em;
}

.lobby-start-game {
  transition: filter 0.3s;
}

.lobby-start-game:not(.lobby-start-game-enabled) {
  filter: grayscale(80%) brightness(50%);
  pointer-events: none;
}

.lobby-button-not-ready {
  background-color: red;
}
.lobby-main-button::after {
  display: block;
  font-size: 0.5em;
}
.lobby-button-ready {
  filter: hue-rotate(90deg);
}
.lobby-button-not-ready::after {
  content: "Click to mark yourself as ready";
}
.lobby-button-ready::after {
  content: "Click to mark yourself as not ready";
}

.lobby-invite-box {
  grid-area: invite;
  align-self: start;
  text-align: center;
  display: grid;
  color: gray;

  grid-template-columns: auto 1fr auto;
  grid-template-rows: 36px;
  align-items: center;
  gap: 0.5em;

  height: fit-content;
}

.lobby-invite-box > * {
  user-select: none;
}

.lobby-invite-link {
  padding: 3px;
  border: 1px solid gray;
  user-select: all;
  align-self: stretch;

  display: grid;
  place-content: center;
}

.lobby-invite-copied {
  position: absolute;
  display: inline-block;
  border-radius: 3px;
  border: 1px solid gray;
  color: gray;
  background-color: black;
  padding: 3px;
  font-size: 8pt;
  transform: translateX(-50%);
  user-select: none;

  opacity: 0%;
  pointer-events: none;
}
