.imprint {
  display: grid;
  grid-template-columns: auto;
  place-content: center;
  text-align: center;
}

.imprint-address {
  display: grid;
  text-align: left;
  grid-template-columns: 1fr 1fr;
}

.imprint-captcha {
  max-width: 400px;
}

.imprint > .fancy-button {
  margin-top: 3em;
  place-self: center;
}

.imprint-censored {
  color: transparent;
  background: white;
  user-select: none;
}

.sneaky-imprint-link {
  position: fixed;
  bottom: 0;
  left: 0;
  font-size: x-small;
  margin: 1em;
  color: gray;
}
