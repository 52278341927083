.fancy-button {
  display: inline-block;

  text-decoration: none;
  color: white;
  text-shadow: 0 0 5px black;
  font-weight: bold;
  border: 10px solid transparent;
  font-variant-caps: small-caps;
  font-family: Georgia, "Times New Roman", Times, serif;

  min-width: 96px;

  border-image: url("./button-1.png");
  border-image-slice: 29 30 fill;
  border-image-width: 20;
  border-image-repeat: stretch repeat;

  cursor: pointer;
  user-select: none;
}

.fancy-button:hover {
  border-image-source: url("./button-2.png");
}

.fancy-button:active {
  border-image-source: url("./button-3.png");
}

.button-stack {
  display: grid;
  gap: 2em;
  place-items: stretch;
  grid-template-columns: 1fr auto 1fr;
  margin-top: 3em;
}

.button-stack > * {
  grid-column: 2;
}
