.lobby-rules {
  display: grid;
  grid-template-columns: auto auto auto;
  place-content: start;
}

.lobby-rules > * {
  display: contents;
}

.lobby-rules > li:not(.enabled) {
  color: gray;
}

.lobby-rules input[type="number"] {
  width: 5ch;
}

.lobby-rules > li:not(.enabled) input[type="number"] {
  color: gray;
}

.lobby-rules > li > span {
  cursor: pointer;
  user-select: none;
}
