.main-menu {
  text-align: center;
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 1em;
  place-items: center;
  color: white;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
}

.main-menu:after {
  content: "";
  position: absolute;
  top: -50px;
  left: -50px;
  bottom: -50px;
  right: -50px;
  z-index: -1;
  filter: grayscale(0.6) brightness(0.4);

  background: url("ui-utils/worldMapRef.ts");
  object-fit: cover;
  background-repeat: repeat no-repeat;
  background-size: cover;
  animation: main-menu-animation 300s linear 0s infinite alternate;
}

@keyframes main-menu-animation {
  0% {
    background-position: 0vw;
  }
  100% {
    background-position: 200vw;
  }
}

.main-menu-buttons {
  place-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 3em;
}

.main-menu-buttons > a {
  display: block;
}
