.troop-count {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  grid-template-areas:
    "amount amount"
    "submit abort";
  place-items: center;

  padding: 2px;
  border: 1px solid black;
  border-radius: 6px;
  background-color: white;
  color: black;
}

.troop-count-amount {
  min-width: 2ch;
  text-align: center;
  grid-area: amount;
  cursor: default;
}

.troop-count-button {
  display: block;
  cursor: pointer;
  width: 1em;
  text-align: center;
}

.troop-count-submit:hover {
  color: green;
}
.troop-count-abort:hover {
  color: red;
}

.troop-count-submit {
  grid-area: submit;
}

.troop-count-abort {
  grid-area: abort;
}
