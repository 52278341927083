.editor-continent-list {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

.editor-continent-list col:nth-of-type(1) {
  width: 100%;
}

.editor-continent-list col:nth-of-type(2) {
  width: 5em;
}

.editor-continent-list * {
  border-color: gray;
  border-width: 1px;
}

.editor-continent-list th {
  border-style: solid;
  border-top: none;
  border-right: none;
  border-bottom-style: double;
}

.editor-continent-list td:first-of-type > input {
  width: calc(100% - 5px);
}

.editor-continent-list input {
  width: calc(100% - 3em);
}

.editor-continent-list th:first-of-type {
  padding-right: 1em;
  border-left: none;
}

.editor-continent-list tr * {
  cursor: pointer;
}

.editor-continent-list tr.editor-continent-selected {
  background-color: darkblue;
  color: white;
}

.editor-modes {
  display: grid;
  grid-auto-flow: row;
  gap: 3px;
}

.editor-mode {
  display: block;
  background-color: lightgray;
  border: 1px solid gray;
  padding: calc(1px + 1px);
  border-radius: 3px;
}

.editor-mode-selected {
  border-width: 2px;
  border-color: black;
  padding: calc(1px + 0px);
}

.editor-choose-name-modal {
  display: grid;
  background: hsla(0deg, 0%, 0%, 50%);
  border: 1px solid black;
  grid-template-columns: auto auto;
  place-content: center;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.editor-choose-name-modal-buttons {
  grid-column: span 2;

  display: grid;
  grid-template-columns: 1fr 1fr;
}
