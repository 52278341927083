.movement-edges {
  filter: drop-shadow(1px 0 0 black) drop-shadow(0 1px 0 black)
    drop-shadow(-1px 0 0 black) drop-shadow(0 -1px 0 black)
    drop-shadow(0 0 10px rgba(0, 0, 0, 0%));
  stroke-width: 2px;
}

.movement-edges line {
  stroke: red;
}
.movement-edges marker {
  fill: red;
}

.movement-edges-firstSelected line {
  animation: linear 0.3s forwards movement-edge-appear;

  stroke-dasharray: 1;
  stroke-dashoffset: 1;
}

.movement-edges-firstSelected marker {
  animation: linear 0.1s 0.25s forwards movement-arrowhead-appear;
  fill: transparent;
}

.movement-edges-bothSelected,
.movement-edges-attacking {
  stroke-width: 3px;
}

@keyframes movement-edge-appear {
  from {
    stroke-dashoffset: 1;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes movement-arrowhead-appear {
  from {
    fill: transparent;
  }
  to {
    fill: red;
  }
}

.log-edges .edge-arrow {
  filter: drop-shadow(1px 0 0 white) drop-shadow(0 1px 0 white)
    drop-shadow(-1px 0 0 white) drop-shadow(0 -1px 0 white) opacity(0.5);
  fill: none;
}

.log-edges text {
  filter: drop-shadow(1px 0 0 white) drop-shadow(0 1px 0 white)
    drop-shadow(-1px 0 0 white) drop-shadow(0 -1px 0 white);
  font-size: 0.7em;
  font-weight: lighter;
}

.log-edges-movements {
  stroke: black;
  fill: black;
}

.log-edges-movements .edge-arrow {
  marker-end: url(#logMoveArrow);
}

#logMoveArrow {
  fill: black;
  stroke: none;
}

.log-edges-attacks {
  stroke: red;
}
.log-edges-attacks .edge-arrow {
  marker-end: url(#logAttackArrow);
}
#logAttackArrow {
  fill: red;
  stroke: none;
}
