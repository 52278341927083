.next-phase-button {
  place-self: end;
  width: calc(100px - 1em);
  display: grid;
  grid-auto-flow: row;
  place-items: center;
  grid-template-rows: 0.5em auto;
  gap: 0.7em;

  transition: transform 0.5s;
}

.next-phase-button-arrow {
  font-size: 2em;
}
.next-phase-button-flavour {
  font-size: 0.66em;
}

.next-phase-button-hidden {
  transform: translateY(calc(100% + 1em));
}
.next-phase-button-hidden .next-phase-button-flavour {
  color: gray;
}
