.end-game-overlay-wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  background: hsla(0deg, 0%, 0%, 75%);
  /* backdrop-filter: blur(3px); */

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.end-game-overlay {
  text-align: center;
}

.end-game-overlay h1 {
  font-size: 3em;
}

.end-game-player-list {
  display: flex;
  gap: 1em;
  margin-bottom: 1em;
  align-content: center;
  justify-content: center;
}

.end-game-overlay .player-box-data {
  place-self: stretch;

  text-align: center;
  background: transparent;
  border: 1px solid gray;
  border-left: none;
  display: grid;
  place-items: center;
}

.end-game-overlay .end-game-player-list-1 .player-box-data {
  font-size: 48px;
}

.end-game-overlay .end-game-player-list-23 .player-box-data {
  font-size: 40px;
}

.end-game-overlay .end-game-player-list-n .player-box-data {
  font-size: 24px;
}

.end-game-overlay .player-box {
  overflow: hidden;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
