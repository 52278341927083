.player-box {
  display: inline-grid;
  text-align: end;
  align-items: center;
  justify-items: stretch;
  grid-template-columns: 64px 64px;
  grid-template-rows: auto auto;
}

.player-box-name {
  place-self: center;
  width: 128px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  grid-column-start: span 2;
  text-align: center;
  padding: 3px;
  box-sizing: border-box;
}

.player-box-avatar {
  border: 1px solid gray;
  background: hsl(0 0% 20%);
  aspect-ratio: 1/1;
  color: gray;
  width: 64px;
  height: 64px;
  overflow: hidden;
  text-align: center;
  box-sizing: border-box;
  user-select: none;
}
