.credits-wrapper {
  text-align: center;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  overflow-y: scroll;

  display: grid;
  place-items: center;
}

.credits-auto-scroll-button {
  position: fixed;
  right: 0;
  bottom: 0;
  transition: transform 0.1s, font-size 0.1s;

  border-radius: 100%;
  width: 64px;
  height: 64px;
  border: 1px solid white;
  display: grid;
  place-items: center;
  font-size: 24px;
  margin: 32px;
  cursor: pointer;
}

.credits-auto-scroll-button.hidden {
  transform: translateY(200%);
}

.credits p small {
  display: block;
  font-size: 0.7em;
}

.credits-entry-name {
  font-size: large;
}

.credits-entry-metadata {
  font-size: small;
}

.credits a:not(.fancy-button) {
  color: gray;
  text-decoration: underline;
  max-width: 400px;
  white-space: nowrap;
  overflow-x: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  vertical-align: top;
}

.credits-entry-image {
  display: grid;
  margin: 5em;
  gap: 1em;
  text-align: left;
}

.credits-entry-image:nth-of-type(2n) {
  grid-template-areas: "image text";
  grid-template-columns: auto 1fr;
}
.credits-entry-image:nth-of-type(2n + 1) {
  grid-template-areas: "text image";
  grid-template-columns: 1fr auto;
}

.credits-entry-image > img {
  border: 1px solid lightgray;
  background: gray;
  grid-area: image;
  border-radius: 5px;
}
.credits-entry-image > div {
  grid-area: text;
}

.credits-entry-sound {
  display: grid;
  margin: 3em;
  gap: 0.5em;

  grid-template-columns: auto 1fr;
  place-items: center;
}

.credits-entry-sound-play {
  grid-row: span 2;
  font-size: x-large;
  cursor: pointer;
}

.credits-entry-sound .credits-entry-metadata {
  grid-column: 2;
}

.credits-entry-fullscreen {
  display: flex;
  flex-direction: column;
  place-content: center;
  height: 100vh;
}

.credits-entry-halfscreen {
  height: 50vh;
}
