.choose-player-name {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  max-width: 900px;
  text-align: center;
}

.joined-players-list {
  margin-top: 1em;
  display: flex;
  gap: 1em;
  align-items: center;
}

.joined-player {
  display: grid;
  grid-template-columns: 64px 64px;
}

.joined-player .lobby-player-name {
  grid-column-end: span 2;
}

.joined-player:not(.joined-players-connected) .lobby-player-name::after {
  content: "(disconnected)";
}

.joined-players-connected div:last-of-type {
  font-size: 0.6em;
  place-self: center;
}
