.player-info-list {
  display: flex;
  flex-direction: column;
  place-items: center;
  gap: 1em;
  height: 100%;
  justify-content: space-around;
}

.player-info-data {
  display: grid;
  grid-template-columns: auto 1fr auto;
  /* border: 1px dotted red; */
}

.player-info-state {
  grid-column-start: span 2;
  text-align: center;
}

.player-info-data * {
  padding-left: 2px;
}

.player-info-span {
  grid-column-start: span 2;
}

.player-info-symbol {
  place-self: center;
}

.player-info-row {
  display: contents;
  font-size: 9pt;
}

.player-info-row-clickable {
  text-shadow: 0 0 3px white;
  color: gold;
  cursor: pointer;
}

.player-info-breadcrumbs {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto 1fr;
  gap: 5px;
  margin-top: 6px;
  margin-bottom: 3px;
  margin-inline: 3px;
  place-items: center;
  height: 7px;
}
.player-info-breadcrumbs * {
  background-color: hsla(0, 0%, 100%, 20%);
}
.player-info-breadcrumbs-line {
  height: 1px;
  width: 100%;
}
.player-info-breadcrumbs-circle {
  width: 5px;
  height: 5px;
  border-radius: 100%;

  transition: all 0.5s;
}
.player-info-breadcrumbs-circle.player-info-breadcrumbs-active {
  /* width: 7px;
  height: 7px; */
  background-color: white;
  filter: drop-shadow(0 0 2px white);
}

.player-info-state {
  color: gray;
  transition: color 0.5s;
  max-width: 128px;
  overflow: hidden;
}
.player-info-active .player-info-state {
  color: white;
}

.player-info {
  transition: translate 0.5s, box-shadow 0.3s;
}

.player-info-active {
  translate: 20px 0;
  box-shadow: black -20px 0 50px;
}
