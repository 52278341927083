.dropdown-button {
  cursor: pointer;
  user-select: none;
  border-radius: 3px;
}

.dropdown-button:hover,
div.dropdown-button.dropdown-open {
  background-color: hsla(0, 0%, 100%, 50%);
}

.dropdown-list {
  position: absolute;
  border: 1px solid black;
  background-color: white;
  cursor: pointer;
  user-select: none;
}
