.map-troop-count {
  pointer-events: none;
  user-select: none;
}

.map-node-placed {
  stroke-width: 2px;
}

.map-troops-count-placed {
  font-weight: bold;
}

.map-node-popout {
  stroke-width: 1px;
  stroke: black;
  fill: white;
}

.map-node-popout-arc {
  transition: transform 0.25s;
  transform: var(--transform-arc);
}
.map-node-popout-rect {
  transition: transform 0.25s;
  transform: var(--transform-rect);
}

.map-node-popout {
  --transform-arc: none;
  --transform-rect: scaleX(0);
}
.map-node-popout.map-node-popout-visible {
  --transform-arc: translateX(30px);
  --transform-rect: scaleX(31);
}

.map-node-popout text {
  transition: transform 0.25s;
  font-size: 0.8em;
  font-weight: 200;
}
.map-node-popout.map-node-popout-visible text {
  transform: translateX(33px);
}
