body {
  background: rgb(26, 33, 43);
  color: white;
}

h1 {
  text-align: center;
  font-variant: small-caps;
  font-family: Georgia, "Times New Roman", Times, serif;
  padding-top: 1em;
  font-size: 2.5em;
}
h1:after {
  content: ".";
}

.App {
  overflow: hidden;
  display: grid;
  grid-template-columns: 140px 1fr 140px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  gap: 0px;
  place-items: start;
}

.map-canvas {
  max-width: 100%;
  max-height: 100%;
  place-self: center;
}

.map-canvas foreignObject {
  pointer-events: none;
}

.map-canvas foreignObject * {
  pointer-events: auto;
}

.map-positioned-overlay {
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
}

.map-instructions {
  position: absolute;
  bottom: 3em;
  left: 50%;
  transform: translateX(-50%);
  color: lightgray;
  font-style: italic;
  text-align: center;
  text-shadow: 0 0 0.5em black, 0 0 0.5em black, 0 0 0.5em black;
}

.map-instructions-remove {
  font-size: 0.5em;
  font-style: normal;
  text-decoration: underline;
  color: gray;
  border: none;
  background: none;
  cursor: pointer;
}

.shadow {
  filter: drop-shadow(0px 0px 2px white) drop-shadow(0px 0px 2px white)
    drop-shadow(0px 0px 2px white);
}

.remaining-troops {
  background-color: red;
  border-radius: 0.4em;
  color: white;
  font-weight: bold;
  padding: 0.2em;
  text-align: center;
  cursor: pointer;
  user-select: none;
  border: 1px solid black;
}

.ping-display {
  position: absolute;
  top: 0;
  right: 0;
  font-family: "Courier New", Courier, monospace;
  color: gray;
  font-size: 0.7em;
  text-align: right;
}

.ping-display::after {
  content: "ping";
  display: block;
  font-size: 0.7em;
}

.connection-state {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.game-column-right {
  place-self: stretch;
  display: grid;
  grid-template-rows: 1fr auto;
  place-items: start;
}
